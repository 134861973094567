import React, { useState, useEffect, useRef } from "react";
import "./style.css";

// Konfiguracja parametrów
const config = {
  starCount: 60,   // Liczba gwiazd
  minSpeed: 1,       // Minimalna prędkość gwiazdy
  maxSpeed: 5,       // Maksymalna prędkość gwiazdy
  minScale: 0.1,     // Minimalny rozmiar gwiazdy
  maxScale: 0.4,     // Maksymalny rozmiar gwiazdy
  scaleDecay: 0.01,  // Tempo zmniejszania się skali gwiazdy
  minScaleReset: 0.1,// Minimalna skala przed resetem
  direction: -1,     // Kierunek ruchu: 1 = wciąganie do kursora, -1 = oddalanie od kursora
};

// --- Komponent gwiazdy ---
const Star = ({ x, y, scale }) => {
  return (
    <div
      className="star"
      style={{
        transform: `translate(${x}px, ${y}px) scale(${scale})`,
      }}
    />
  );
};

// --- Komponent linii ---
// Przyjmuje dodatkowo "progress", który określa, jaka część docelowej długości linii ma być aktualnie narysowana.
const Line = ({ cursorX, cursorY, starX, starY, progress }) => {
  const dx = starX - cursorX;
  const dy = starY - cursorY;
  const fullLength = Math.sqrt(dx * dx + dy * dy);
  const angle = (Math.atan2(dy, dx) * 180) / Math.PI;

  // Długość rysowanej części linii
  const currentLength = fullLength * progress-100;

  return (
    <div
      className="line"
      style={{
        // "width" to długość części linii, którą rysujemy
        width: `${currentLength}px`,
        transform: `translate(${cursorX}px, ${cursorY}px) rotate(${angle}deg)`,
      }}
    />
  );
};

const Stars = () => {
  const [stars, setStars] = useState([]);
  const [cursor, setCursor] = useState({ x: 0, y: 0 });

  // lines przechowuje strukturę:
  // [
  //   {
  //     starId: number,
  //     progress: number (0..1)
  //   }
  // ]
  const [lines, setLines] = useState([]);

  const animationFrameRef = useRef();

  // --- Inicjalizacja gwiazd ---
  useEffect(() => {
    const generateStars = () => {
      const starArray = [];
      for (let i = 0; i < config.starCount; i++) {
        starArray.push(createStar());
      }
      setStars(starArray);
    };

    generateStars();
  }, []);

  const createStar = () => {
    const randomSpeed =
      Math.random() * (config.maxSpeed - config.minSpeed) + config.minSpeed;
    const randomScale =
      Math.random() * (config.maxScale - config.minScale) + config.minScale;
    return {
      id: Math.random(), // Unikalne ID gwiazdy
      x: Math.random() * window.innerWidth - window.innerWidth / 2,
      y: Math.random() * window.innerHeight - window.innerHeight / 2,
      scale: randomScale,
      speed: randomSpeed,
    };
  };

  // --- Funkcja animująca gwiazdy i linie ---
  useEffect(() => {
    const animate = () => {
      // 1) Aktualizujemy pozycję i skalę gwiazd
      setStars((prevStars) =>
        prevStars.map((star) => {
          const dx = cursor.x - star.x;
          const dy = cursor.y - star.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          // Kierunek ruchu: zależny od config.direction
          const vx = (dx / distance) * star.speed * config.direction;
          const vy = (dy / distance) * star.speed * config.direction;

          const newX = star.x + vx;
          const newY = star.y + vy;

          // Zmniejszanie skali gwiazdy
          const newScale = star.scale - config.scaleDecay;

          // Resetowanie gwiazdy, gdy skala osiągnie minimalną wartość
          if (newScale <= config.minScaleReset) {
            return createStar();
          }

          return {
            ...star,
            x: newX,
            y: newY,
            scale: newScale,
          };
        })
      );

      // 2) Aktualizujemy linie
      setLines((prevLines) => {
        // Najpierw przygotujmy ID gwiazd, które w tym momencie są w top 10 najbliższych
        const nearestStars = getNearestStars(stars, cursor.x, cursor.y, 10);
        const nearestIds = nearestStars.map((s) => s.id);

        // Zwiększamy "progress" dla wszystkich linii, które odnoszą się do nadal najbliższych gwiazd
        // a usuwamy linie, których gwiazda nie jest już w najbliższych.
        const updatedLines = prevLines
          .map((line) => {
            if (!nearestIds.includes(line.starId)) {
              // Gwiazda przestała być w najbliższych -> usuwamy linię
              return null;
            }
            // Gwiazda wciąż jest w najbliższych -> zwiększamy progress
            let newProgress = line.progress + 0.012; // tempo rysowania (dostosuj według uznania)
            if (newProgress > 1) {
              newProgress = 1; // Linia w pełni dorysowana
            }
            return { ...line, progress: newProgress };
          })
          .filter(Boolean);

        // Dodajemy linie dla nowych gwiazd, dla których jeszcze nie ma linii
        nearestIds.forEach((id) => {
          const alreadyExists = updatedLines.some((line) => line.starId === id);
          if (!alreadyExists) {
            updatedLines.push({ starId: id, progress: 0 });
          }
        });

        return updatedLines;
      });

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [cursor, stars]);

  // --- Obsługa ruchu myszką ---
  const handleMouseMove = (e) => {
    const x = e.clientX - window.innerWidth / 2;
    const y = e.clientY - window.innerHeight / 2;
    setCursor({ x, y });
  };

  // --- Funkcja pomocnicza szukająca najbliższych gwiazd ---
  const getNearestStars = (allStars, cx, cy, count) => {
    return allStars
      .map((star) => ({
        ...star,
        distance: Math.sqrt(Math.pow(star.x - cx, 2) + Math.pow(star.y - cy, 2)),
      }))
      .sort((a, b) => a.distance - b.distance)
      .slice(0, count);
  };

  return (
    <div id="apps" onMouseMove={handleMouseMove} >
      {/* Rysowanie gwiazd */}
      {stars.map((star) => (
        <Star key={star.id} x={star.x} y={star.y} scale={star.scale} />
      ))}

      {/* Rysowanie linii z progresywnym wydłużaniem */}
      {lines.map((line, index) => {
        // Szukamy aktualnych współrzędnych gwiazdy
        const star = stars.find((s) => s.id === line.starId);
        if (!star) return null; // Teoretycznie może się zdarzyć, jeśli gwiazda została zresetowana

        return (
          <Line
            key={index}
            cursorX={cursor.x}
            cursorY={cursor.y}
            starX={star.x}
            starY={star.y}
            progress={line.progress}
          />
        );
      })}
    </div>
  );
};

export default Stars;
