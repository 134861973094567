import React, { useEffect, useState } from "react";
//import { Button, Space, Swiper, Toast } from 'antd-mobile'
import VisibilitySensor from 'react-visibility-sensor';
import { useSettingsCtx } from "../../context/SettingsContext";
import { fetchStaticJson } from './../../services/useApi';
import './style.css'
import SafeDial from '../SafeDial'
import Rain from './../Rain'
//import { Timeline } from 'antd';
import { Col, Row, Statistic, Card, Divider } from 'antd';
import Stars from './../Stars'
const { Countdown } = Statistic;





const SomePage3 = ({ page }) => {
  // background,backgroundSize,backgroundPositionY, 
  const { setMainSection } = useSettingsCtx();
  const [backgroundSize, setBackgroundSize] = useState(150)
  const [backgroundPositionY, setBackgroundPositionY] = useState(100)
  const [liveData, setLiveData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [scrollTopState, setScrollTopState] = useState(100);

  const now = new Date();

  let utcNow = Date.UTC(now.getUTCFullYear(), 1, 6, 19, 1, 0); // Ustawienie na 19:30 UTC
  const utcNowNext = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 22, 21, 37, 0); // Ustawienie na 19:30 UTC

  const generateRandomFutureDate = () => {
    const now = new Date();
    // Generate a random number of hours from now, between 1 and 96 hours (4 days)
    const randomHours = Math.floor(Math.random() * 96 + 1);
    return new Date(now.getTime() + randomHours * 60 * 60 * 1000);
  };

  // const [targetDate, setTargetDate] = useState(generateRandomFutureDate());

  // useEffect(() => {
  //   // Update the target date every selected interval, e.g., every 24 hours
  //   const interval = setInterval(() => {
  //     setTargetDate(generateRandomFutureDate());
  //   }, 300); // Update every 24 hours

  //   return () => clearInterval(interval);
  // }, []);
  // utcNow = targetDate
  const targetDate = new Date(Date.UTC(2025, 3, 1, 0, 0, 0));

  // useEffect(() => {
  //   // Funkcja do pobierania danych
  //   const fetchData = async () => {
  //     try {
  //       const jsonData = await fetchStaticJson('/live/datalog.json');
  //       // Sprawdź, czy dane są aktualne
  //       if (new Date() - new Date(jsonData.timestamp) < 5 * 60 * 1000) {
  //         setLiveData(jsonData);
  //       } else {
  //         setLiveData(null); // Dane nieaktualne
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching live data:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  //   // Ustaw interwał do odświeżania danych co 5 sekund
  //   const interval = setInterval(fetchData, 5000);

  //   // Wyczyść interwał przy odmontowywaniu komponentu
  //   return () => clearInterval(interval);
  // }, []);

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }



  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      setMainSection("SomePage3");
    }
  };

  const handleScroll = (event) => {
    const scrollTop = event.currentTarget.scrollTop;

    if (scrollTop > 1) {
      //setScrollTopState(220)
      setBackgroundSize(135)
      setBackgroundPositionY(-190)
    } else {
      //setScrollTopState(100)
      setBackgroundSize(150)
      setBackgroundPositionY(100)
    }


  };

  const style = {
    backgroundImage: `url(${page.background})`,
    backgroundSize: backgroundSize + '%',
    backgroundPositionX: 'center',
    backgroundPositionY: backgroundPositionY + '%',
    height: 'calc(90dvh)',
    backgroundColor: 'rgb(2 10 13)',
    paddingTop: '33dvh'

  };


  return (
    <VisibilitySensor onChange={handleVisibilityChange}>
      <div className="contentSubPageBackground livePage" style={style}>
        {/* <Rain    drops={scrollTopState}   /> */}
        <div className="contentSubPage scrollbar-thin"
          onScroll={handleScroll}
          style={{ height: '65dvh', padding: '0vw 5vw' }}
        >
          {/* <SafeDial number={45}/> */}
          
          {/* <div className="container"> */}
          <div style={{ height: '46dvh', }}></div>

          {/* <Row gutter={16}>
            {loading ? (
              <Col span={12}>
                <Card>
                  <Statistic title="Loading..." value="Fetching data..." />
                </Card>
              </Col>
            ) : liveData && !liveData.error ? (
              Object.entries(liveData)
                .filter(([key]) => key !== 'timestamp') // Wykluczenie timestamp
                .map(([key, value]) => (
                  <Col span={12} key={key}>
                    <Card>
                      <Statistic
                        title={key.charAt(0).toUpperCase() + key.slice(1)}
                        value={value}
                        precision={key === 'speed' || key === 'distance' ? 4 : 0}
                        valueStyle={{ transition: 'value 0.3s ease-in-out' }}
                      />
                    </Card>
                  </Col>
                ))
            ) : (
              <Col span={12}>
                <Card>
                  <Statistic title="No Data" value="No data to display" />
                </Card>
              </Col>
            )}
          </Row> */}
          {/* <Countdown
            title="Awaiting Clear Skies: Next Live Adventure Countdown"
            //title="It's still raining, I have to reschedule today's live for another time."
            //title="Today's live is postponed to the next time which is already in"
            value={utcNow} format="D:HH:mm:ss:SSS" />


          <div className="dataTime">23.03.2024</div>

          <p><span style={{ color: 'white' }}>Hello everyone,</span></p> */}

<div>
<h2>Site Undergoing Complete Rebuild</h2><br/><br/><br/>

            <p><strong>Dear Visitors,</strong></p>
            <p>
              We are currently performing a complete overhaul of our website to serve you better. 
              During this period, some or all sections of the site may be temporarily unavailable.
            </p>
            <p>
              <strong>Countdown to Reopening:</strong>
            </p>
            <Countdown
              title="Our site will be available again between Q1 and Q2 of 2025."
              value={targetDate}
              format="D:HH:mm:ss"
            />
            <p>
              We appreciate your patience and understanding. Stay tuned and come back soon!
            </p>
            
          </div>


          <p>For live updates and future exploration dates, make sure to follow me on <a href="https://www.tiktok.com/@emeneobus" >@emeneobus</a>.</p>
          <div style={{ height: '30dvh' }}></div>
        </div>
      </div>
      {/* </div> */}
    </VisibilitySensor>
  );
};

SomePage3.label = "Some Page3";
export default SomePage3;