import React, { useState, useEffect,useRef } from 'react';
import { Swiper } from 'antd-mobile';
import SomePage1 from "./../../components/SomePage1";
import SomePage2 from "./../../components/SomePage2";
import SomePage3 from "./../../components/SomePage3";
import SomePage4 from "./../../components/SomePage4";
import SomePage5 from "./../../components/SomePage5";
import MainMenu from "./../../components/MainMenu"
import Stars from "./../../components/Stars"
import Layout from "./../../components/Layout"
import './index.css'
//import treeIcon from "./svg/tree.svg";
import { ReactComponent as TreeIcon } from "./svg/tree.svg";
//import liveIcon from "./img/live-button.png";
import equipmentBackground from './img/equipment-bg.jpeg';
import galleryBackground from './img/gallery-bg.jpeg';
import liveBackground from './img/live-bg.jpeg';
import qaBackground from './img/qa-bg.jpeg';
import guestBookBackground from './img/guest-bg.jpeg';

import fog01 from './img/fog01.png';
import fog02 from './img/fog02.png';
import fog03 from './img/fog03.png';
import fog04 from './img/fog04.png';
import fog05 from './img/fog05.png';
import fog06 from './img/fog06.png';
import fog07 from './img/fog07.png';
import fog08 from './img/fog08.png';
import fog09 from './img/fog09.png';
import fog10 from './img/fog10.png';
import fogupbgpng from './img/fog-up-bg.png';


import Compass from './../../components/MainMenu/buttons/Compass';

const pages = [
    {
        label: "Equipment",
        icon: TreeIcon,
        component: SomePage1,
        background: equipmentBackground,
        buttonComponent: <Compass background={equipmentBackground}></Compass>,
    },
    {
        label: "Gallery",
        icon: TreeIcon, // Zastąp odpowiednim SVG
        component: SomePage2,
        background: galleryBackground,
        backgroundSize: '200',
        backgroundPositionY:75,
        buttonComponent: <Compass background={galleryBackground}></Compass>,
    },
    {
        label: "LIVE",
        icon: TreeIcon, // Zastąp odpowiednim SVG
        component: SomePage3,
        background: liveBackground,
        backgroundSize: '200',
        backgroundPositionY:75,
        buttonComponent: <Compass background={liveBackground}></Compass>,
    },
    {
        label: "Q&A",
        icon: TreeIcon, // Zastąp odpowiednim SVG
        component: SomePage4,
        background: qaBackground,
        backgroundSize: '200',
        backgroundPositionY:75,
        buttonComponent: <Compass background={qaBackground}></Compass>,
    },
    {
        label: "Guest book",
        icon: TreeIcon, // Zastąp odpowiednim SVG
        component: SomePage5,
        background: guestBookBackground,
        backgroundSize: undefined,
        backgroundPositionY:undefined,
        buttonComponent: <Compass background={guestBookBackground}></Compass>,
    },
];

const PageWithBackground = ({children }) => {
    const style = {
        height: 'calc(90dvh)',
    };
    return <div style={style}  className="sub-page-background scrollbar-thin">{children}</div>;
};

const Fog = ({className}) => {

    return     <div className={`fog ${className} `}><Stars/>
    <img src={fog01} className="fogImage left" style={{ '--i': 1 }} />
    <img src={fog02} className="fogImage left" style={{ '--i': 2 }} />
    <img src={fog03} className="fogImage left" style={{ '--i': 3 }} />
    <img src={fog04} className="fogImage left" style={{ '--i': 4 }} />
    <img src={fog05} className="fogImage left" style={{ '--i': 5 }} />
    <img src={fog06} className="fogImage right" style={{ '--i': 6 }} />
    <img src={fog07} className="fogImage right" style={{ '--i': 7 }} />
    <img src={fog08} className="fogImage right" style={{ '--i': 8 }} />
    <img src={fog09} className="fogImage right" style={{ '--i': 9 }} />
    <img src={fog10} className="fogImage right" style={{ '--i': 10 }} />
    {className === 'bottom-fog' ? <div className="fogImage mask"  ></div>: ''}
</div>
}

const Home = () => {
    const [currentIndex, setCurrentIndex] = useState(2);

    const swiperRef = useRef(null);
    const swiperSwipeTo = (newIndex) => {
        swiperRef.current.swipeTo(newIndex)
    }

    const [scrollDistance, setScrollDistance] = useState(0);
    const handleScroll = (distance) => {
      //  console.log("Przewinięto o: ", distance);
        setScrollDistance(distance);
        //page.backgroundSize
      };

    return (
        <>
            <Layout>
                <div className='homeContener'>
                <Swiper 
                indicator={() => null}
                 ref={swiperRef}
                    direction='horizontal'
                    defaultIndex={currentIndex} 
                    onIndexChange={(index) => setCurrentIndex(index)}
                    style={{ '--height': 'auto', '--width': '100%' }}
                >
                    {pages.map((page, idx) => (
                        <Swiper.Item key={idx}>
                            <PageWithBackground >
                                {React.createElement(page.component,{ page: page })}
                            </PageWithBackground>
                                  
                        </Swiper.Item>
                    ))}
                </Swiper>    
                
                   <Fog className='bottom-fog'/>
                    <MainMenu pages={pages} currentIndex={currentIndex} swiperSwipeTo={swiperSwipeTo} />
                </div>
            </Layout>
        </>
    );
};

export default Home;
